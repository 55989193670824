import { Text } from '@gameonsports/components/cjs/TextV3'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import BoxContainer from '../../components/BoxContainer'
import FullWidthBlock from '../../components/FullWidthBlock'
import { media } from '../../utils/styled-components-utils'

const StyledFullWidthBlock = styled(FullWidthBlock)`
  padding-top: 2rem;
  padding-bottom: 4.5rem;
`

const StyledBox = styled(BoxContainer)`
  display: grid;
  grid-gap: 0.5rem 3.75rem;
  justify-content: center;
  text-align: center;

  svg {
    margin: 0 auto;
  }

  p {
    color: ${props => props.theme.darkGrey400};
  }

  .face {
    fill: ${props => props.theme.blueberry400};
  }

  .speechBubble {
    fill: ${props => props.theme.blackberry400};
  }

  ${media.tablet`
    margin-top: 7rem;
    padding: 4rem 4.75rem;
    text-align: initial;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      'img title'
      'img message';
    svg {
      grid-area: img;
    }

    h1 {
      grid-area: title;
    }

    p {
      grid-area: message;
    }
  `}
`

const Link = styled.a`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const FourOhFour = (_: RouteComponentProps) => (
  <>
    <Helmet
      title="Page Not Found - Sports Leagues, Competitions, Stats"
      meta={[
        {
          name: 'description',
          content:
            "The page you're looking for isn’t available on PlayHQ. Explore leagues, fixtures, teams, and stats for sports like AFL, cricket, and netball, or return to the homepage to continue.",
        },
      ]}
    />
    <StyledFullWidthBlock>
      <StyledBox>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="90.625"
          viewBox="0 0 100 90.625"
        >
          <g transform="translate(0 -4.688)">
            <path
              d="M40.625 59.375a3.124 3.124 0 0 0 3.125-3.125c0-2.98 3.27-6.25 6.25-6.25s6.25 3.27 6.25 6.25a3.125 3.125 0 0 0 6.25 0c0-6.426-6.074-12.5-12.5-12.5s-12.5 6.074-12.5 12.5a3.124 3.124 0 0 0 3.125 3.125z"
              className="face"
            />
            <path
              d="M34.375 34.375A3.121 3.121 0 0 0 37.5 31.25a3.072 3.072 0 0 0-.922-2.2 3.2 3.2 0 0 0-4.406 0 3.072 3.072 0 0 0-.922 2.2 3.121 3.121 0 0 0 3.125 3.125z"
              className="face"
            />
            <path
              d="M65.625 34.375a3.127 3.127 0 0 0 2.2-5.344 3.216 3.216 0 0 0-4.406.016 3.072 3.072 0 0 0-.922 2.2 3.121 3.121 0 0 0 3.125 3.125z"
              className="face"
            />
            <path
              d="M0 73.438a3.124 3.124 0 0 0 3.125 3.125H25v15.625a3.128 3.128 0 0 0 5.16 2.375l21-18h45.715A3.124 3.124 0 0 0 100 73.438V7.813a3.124 3.124 0 0 0-3.125-3.125H3.125A3.124 3.124 0 0 0 0 7.813zm6.25-62.5h87.5v59.375H50a3.132 3.132 0 0 0-2.035.75L31.25 85.391V73.438a3.124 3.124 0 0 0-3.125-3.125H6.25z"
              className="speechBubble"
            />
          </g>
        </svg>

        <Text as="h1" weight="700" size="30">
          Oops, not another 404!
        </Text>
        <Text as="p" size="20">
          Sorry you have arrived here. Just to make sure it’s not us, please
          check{' '}
          <Link
            href="https://support.playhq.com/hc/en-au/articles/11715566647449-PlayHQ-Service-Status"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </Link>{' '}
          for current performance. If all services are operational go to{' '}
          <Link href="https://www.playhq.com/" rel="noopener noreferrer">
            playhq.com
          </Link>
          .
        </Text>
      </StyledBox>
    </StyledFullWidthBlock>
  </>
)

export default FourOhFour
